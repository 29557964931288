import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Card, Form, Modal } from 'react-bootstrap';
import { addDoc,getFirestore, collection, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from './firebase/firebaseConfig';
import Timer from './components/Timer';
import Cofeebreack from './assets/coffee.png';

const Simulacion180 = () => {
  const [questions, setQuestions] = useState([]);
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isOnBreak, setIsOnBreak] = useState(false);
  const [questionsLoaded, setQuestionsLoaded] = useState(false);
  const [examFinished, setExamFinished] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [summary, setSummary] = useState({});
  const [timerActive, setTimerActive] = useState(true);

  const db = getFirestore();

  const shuffle = (array) => array.sort(() => Math.random() - 0.5);

  useEffect(() => {
    const loadQuestionsFromFirestore = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'questions'));
        const data = querySnapshot.docs.map((doc) => doc.data());

        const selectedQuestions = shuffle(data).slice(0, 180);

        const shuffledQuestions = selectedQuestions.map((q) => ({
          ...q,
          options: shuffle(q.options),
        }));

        setQuestions(shuffledQuestions);
        setQuestionsLoaded(true);
      } catch (error) {
        console.error('Error al cargar las preguntas de Firestore:', error);
      }
    };

    loadQuestionsFromFirestore();
  }, [db]);


  const saveResultsToFirestore = async (results) => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    const newResult = {
      totalCorrect: results.totalCorrect,
      totalIncorrect: results.totalIncorrect,
      totalAnswered: results.totalAnswered,
      percentage: results.percentage,
      passed: results.percentage >= 51,
      userId: user ? user.uid : 'anónimo',
      timestamp: new Date(),
      
    };
  
    try {
      await addDoc(collection(db, 'examResults'), newResult);
      console.log('Resultados del examen guardados en Firestore');
    } catch (error) {
      console.error('Error al guardar los resultados en Firestore:', error);
    }
  };


  const getCurrentBlockQuestions = () => {
    const start = currentBlockIndex * 90;
    const end = start + 90;
    return questions.slice(start, end);
  };

  const handleAnswerSelect = (questionIndex, option) => {
    setSelectedAnswers({ ...selectedAnswers, [questionIndex]: option });
  };

  const handleManualFeedback = () => {
    if (currentBlockIndex === 1) {
      // Generar el feedback
      generateFeedback();
      calculateSummary();
      saveResultsToFirestore(summary); // Guardar resultados en Firestore

  
      // Cambiar estados para mostrar el feedback y ocultar el segundo bloque
      setExamFinished(true);
      setCurrentBlockIndex(null); // Esto oculta el contenido del bloque actual
  
      // Desplazarse al inicio de la página (feedback)
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Desplazamiento suave
      });
    } else {
      console.error("No se puede mostrar feedback porque no estás en el segundo bloque.");
    }
  };
    

  const handleManualFinish = () => {
    if (currentBlockIndex === 0) {
      setIsOnBreak(true);
    } else if (currentBlockIndex === 1) {
      finishExam();
    }
    setTimerActive(false);
  };

  const handleBreakEnd = () => {
    setIsOnBreak(false);
    setCurrentBlockIndex(1);
    setTimerActive(true);
  };

  const calculateSummary = () => {
    const totalQuestions = questions.length;
    const totalCorrect = questions.filter(
      (q, index) => selectedAnswers[index] === q.answer
    ).length;
    const totalIncorrect = totalQuestions - totalCorrect;
    const percentage = Math.round((totalCorrect / totalQuestions) * 100);

    setSummary({
      totalQuestions,
      totalCorrect,
      totalIncorrect,
      percentage,
      passed: percentage >= 51,
    });
  };

  const generateFeedback = () => {
    const feedbackArray = questions.map((question, index) => ({
      question: question.question,
      correctAnswer: question.answer,
      userAnswer: selectedAnswers[index] || 'No respondida',
      feedback:
        selectedAnswers[index] === question.answer
          ? '¡Correcto!'
          : `Incorrecto. ${question.feedback || ''}`,
    }));
    setFeedback(feedbackArray);
  };

  const finishExam = () => {
    generateFeedback();
    calculateSummary();
    setExamFinished(true);
    setShowSummaryModal(true);
    saveResultsToFirestore(summary); // Guardar resultados en Firestore

  };

  return (
    <Container className="p-4">
      <h3 className="text-center">
        Simulación de 180 Preguntas tipo <strong>EUNACOM</strong>
      </h3>

      {currentBlockIndex === 0 && timerActive && (
        <>
          <Timer initialMinutes={90} onFinish={() => setIsOnBreak(true)} />
          <Row>
            {getCurrentBlockQuestions().map((question, index) => (
              <Col key={index} md={12}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>
                      {`${index + 1 + currentBlockIndex * 90}. ${question.question}`}
                    </Card.Title>
                    <Form>
                      {question.options.map((option, i) => (
                        <Form.Check
                          key={i}
                          type="radio"
                          label={option}
                          name={`question-${index + currentBlockIndex * 90}`}
                          checked={
                            selectedAnswers[index + currentBlockIndex * 90] ===
                            option
                          }
                          onChange={() =>
                            handleAnswerSelect(
                              index + currentBlockIndex * 90,
                              option
                            )
                          }
                        />
                      ))}
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <div className="text-center mt-4">
            <Button variant="primary" onClick={handleManualFinish}>
              Terminar Primer Bloque
            </Button>
          </div>
        </>
      )}

      {isOnBreak && (
        <Modal show centered>
          <Modal.Body className="text-center">
          <img  
             src={Cofeebreack}
             alt="Imagen de descanso"
             style={{
               width: "200px",
               height: "200px",
               objectFit: "cover",
               borderRadius: "50%", // Opcional, si quieres que la imagen sea circular
             }}
            />

            <Timer initialMinutes={30} onFinish={handleBreakEnd} />
          </Modal.Body>
         <Row>
          <Col>
             <p className='text-center'>Descansa tomate un cafe y pon tus ideas en orden</p>
          </Col>
         </Row>
        </Modal>
      )}

      {currentBlockIndex === 1 && timerActive && !isOnBreak && (
        <>
          <Timer initialMinutes={90} onFinish={finishExam} />
          <Row>
            {getCurrentBlockQuestions().map((question, index) => (
              <Col key={index} md={12}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>
                      {`${index + 1 + currentBlockIndex * 90}. ${question.question}`}
                    </Card.Title>
                    <Form>
                      {question.options.map((option, i) => (
                        <Form.Check
                          key={i}
                          type="radio"
                          label={option}
                          name={`question-${index + currentBlockIndex * 90}`}
                          checked={
                            selectedAnswers[index + currentBlockIndex * 90] ===
                            option
                          }
                          onChange={() =>
                            handleAnswerSelect(
                              index + currentBlockIndex * 90,
                              option
                            )
                          }
                        />
                      ))}
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <div className="text-center mt-4">
            <Button 
           variant="primary"
           onClick={() => {
            handleManualFeedback();
             finishExam();
           }}>
              Finalizar Examen
            </Button>
          </div>
        </>
      )}

      {questions.length === 0 || !questionsLoaded ? (
        <p className="text-center">Cargando preguntas...</p>
      ) : examFinished ? (
        <div>
          <Modal
            show={showSummaryModal}
            onHide={() => {
              setShowSummaryModal(false);
              setCurrentBlockIndex(null); // Ocultar el segundo bloque
            }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {summary.passed ? '¡Felicidades! 🎉' : 'Ánimo, sigue intentándolo 😔'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Total de preguntas: {summary.totalQuestions}</p>
              <p>Correctas: {summary.totalCorrect}</p>
              <p>Incorrectas: {summary.totalIncorrect}</p>
              <p>Porcentaje: {summary.percentage}%</p>
            </Modal.Body>
          </Modal>
          <h4 style={{ margin: "3rem" }}>Feedback del Examen</h4>
          {feedback.map((item, index) => (
            <Card key={index} className="mb-3">
              <Card.Body>
                <Card.Title>Pregunta {index + 1}</Card.Title>
                <p>
                  <strong>Pregunta:</strong> {item.question}
                </p>
                <p>
                  <strong>Tu Respuesta:</strong> {item.userAnswer}
                </p>
                <p>
                  <strong>Respuesta Correcta:</strong> {item.correctAnswer}
                </p>
                <p>{item.feedback}</p>
              </Card.Body>
            </Card>
          ))}
        </div>
      ) : null}
    </Container>
  );
};

export default Simulacion180;
