import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig'; // Asegúrate de que este archivo esté configurado correctamente
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/css/tirainformativa.css'; // Asegúrate de importar el CSS

const TiraInformativa = () => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false); // Ocultar la tira al hacer clic en el botón de cerrar
  };

  const [stats, setStats] = useState({
    users: 0,
    totalQuestions: 0,
    timeCreated: 0,
  });

  const [displayStats, setDisplayStats] = useState({
    users: 0,
    totalQuestions: 0,
    timeCreated: 0,
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        // Obtener cantidad de usuarios
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersCount = usersSnapshot.size;

        // Obtener cantidad de preguntas de `questions`
        const questionsCollection = collection(db, 'questions');
        const questionsSnapshot = await getDocs(questionsCollection);
        const questionsCount = questionsSnapshot.size;

        // Inicializar contador para preguntas en xespecialidad
        let xespecialidadQuestionsCount = 0;

        // Obtener documentos de xespecialidad
        const xespecialidadCollection = collection(db, 'xespecialidad');
        const xespecialidadSnapshot = await getDocs(xespecialidadCollection);

        // Contar preguntas en cada subcolección de xespecialidad
        for (const doc of xespecialidadSnapshot.docs) {
          const questionsSubcollection = collection(db, 'xespecialidad', doc.id, 'questions'); // Cambia 'questions' por el nombre real de la subcolección si es diferente
          const questionsInSpecialtySnapshot = await getDocs(questionsSubcollection);
          xespecialidadQuestionsCount += questionsInSpecialtySnapshot.size;
        }

        // Total de preguntas
        const totalQuestions = questionsCount + xespecialidadQuestionsCount;

        // Calcular días desde la creación
        const creationDate = new Date(2023, 5, 25); // Cambia esta fecha si es necesario
        const currentDate = new Date();
        const timeDifference = Math.floor(
          (currentDate - creationDate) / (1000 * 60 * 60 * 24)
        );

        // Actualizar el estado
        setStats({
          users: usersCount,
          totalQuestions,
          timeCreated: timeDifference,
        });
      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  useEffect(() => {
    const animateStats = (key, targetValue) => {
      let currentValue = 0;
      const increment = Math.ceil(targetValue / 100); // Velocidad del conteo
      const interval = setInterval(() => {
        currentValue += increment;
        if (currentValue >= targetValue) {
          currentValue = targetValue;
          clearInterval(interval);
        }
        setDisplayStats((prev) => ({ ...prev, [key]: currentValue }));
      }, 10); // Intervalo de actualización
    };

    if (!isLoading) {
      animateStats('users', stats.users);
      animateStats('totalQuestions', stats.totalQuestions);
      animateStats('timeCreated', stats.timeCreated);
    }
  }, [isLoading, stats]);

  if (isLoading) {
    return <div>Cargando estadísticas...</div>;
  }


  return (
    visible && (
      <div className="tira-informativa">
        <span>⚠️ Recuerda revisar tus respuestas antes de finalizar el bloque. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Nuestros Números: &nbsp;
        <b className='text-black'>Usuarios: +{displayStats.users}</b>&nbsp;&nbsp;
        <b className='text-black'>Total de preguntas: +{displayStats.totalQuestions}</b>&nbsp;&nbsp;
        <b className='text-black'>Dias activos: {displayStats.timeCreated}</b>
        </span> 
        <button onClick={handleClose}>&times;</button>
      </div>
    )
  );
};

export default TiraInformativa;