import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import AOS from 'aos';
import 'aos/dist/aos.css';

const CardGrid = () => {
  const navigate = useNavigate();
  const [userAccess, setUserAccess] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 1000, delay: 200 });

    const fetchUserAccess = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserAccess(userData.sectionsAccess || {});
          } else {
            console.error('Usuario no encontrado en Firestore.');
          }
        } catch (error) {
          console.error('Error al obtener permisos del usuario desde Firestore:', error);
        }
      } else {
        console.error('Usuario no autenticado.');
      }
      setLoading(false);
    };

    fetchUserAccess();
  }, []);

  const handleAccessDenied = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const telegramMessage = `Hola, me gustaría solicitar acceso a esta sección. Mi correo es: ${user.email}`;
      const telegramURL = `https://t.me/+9pWfjFjN6t83Njhh?text=${encodeURIComponent(telegramMessage)}`;
      window.open(telegramURL, '_blank');
    } else {
      alert('Por favor, inicia sesión para solicitar acceso.');
    }
  };

  const cardsData = [
    {
      title: 'Simulación tipo EUNACOM',
      image: '/assets/simulacion.png',
      onClick: () => navigate('/simulacion180'),
      requiresAccess: true,
      accessKey: 'simulacion180',
    },
    {
      title: 'TRIVIA',
      image: '/assets/trivia.png',
      onClick: () => navigate('/trivia'),
      requiresAccess: true,
      accessKey: 'trivia',
    },
    {
      title: 'Exámenes Cortos',
      image: '/assets/examen180.png',
      onClick: () => navigate('/examen'),
      requiresAccess: false,
    },
    {
      title: 'X Especialidad',
      image: '/assets/specialty_exams.png',
      onClick: () => navigate('/examen-especialidad'),
      requiresAccess: true,
      accessKey: 'especialidad',
    },
    {
      title: 'Sección Práctica',
      image: '/assets/practico-seccion.png',
      onClick: () => navigate('/seccion-practica'),
      requiresAccess: true,
      accessKey: 'practica',
    },
    {
      title: 'Historial',
      image: '/assets/history.png',
      onClick: () => navigate('/history'),
      requiresAccess: false,
    },
  ];

  return (
    <Container className="my-4">
      {loading ? (
        <p className="text-center">Cargando permisos...</p>
      ) : (
        <Row className="g-4 justify-content-center">
          {cardsData.map((card, index) => {
            const hasAccess = card.requiresAccess
              ? userAccess[card.accessKey]
              : true;

            return (
              <Col
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="d-flex justify-content-center"
                data-aos="fade-up"
                data-aos-delay={`${index * 100}`}
              >
                <Card
                  style={{
                    width: '100%',
                    height: '300px',
                    backgroundImage: `url(${card.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    cursor: hasAccess ? 'pointer' : 'not-allowed',
                    filter: hasAccess ? 'none' : 'grayscale(100%)',
                  }}
                  className="shadow rounded"
                  onClick={hasAccess ? card.onClick : handleAccessDenied}
                >
                  <Card.Body className="d-flex flex-column justify-content-end">
                    {!hasAccess && (
                      <div className="text-center text-warning bg-dark bg-opacity-75 rounded mt-2">
                        Hacer clic para desbloquear
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </Container>
  );
};

export default CardGrid;
