import React, { useState, useEffect } from 'react';
import {
  Navbar, Nav, NavDropdown, Container, Image,
} from 'react-bootstrap';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import CountdownTimer from './CountdownTimer';
import './css/navbar.css';

const NavigationBar = ({ setIsAuthenticated }) => {
  const [user, setUser] = useState(null);
  const [isDaytime] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'usuarios', currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            setUser({ ...userDoc.data(), photoURL: currentUser.photoURL });
          } else {
            setUser({ email: currentUser.email, photoURL: currentUser.photoURL });
          }
        } catch (error) {
          console.error('Error al obtener los datos del usuario:', error);
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setIsAuthenticated(false);
        navigate('/');
      })
      .catch((error) => console.error('Error cerrando sesión:', error));
  };

  return (
    <Navbar
      expand="lg"
      fixed="top"
      bg={isDaytime ? 'light' : 'dark'}
      variant={isDaytime ? 'light' : 'dark'}
      expanded={expanded}
      className="shadow"
    >
      <Container className='bg-secondary'>
        <Navbar.Brand
          onClick={() => navigate('/')}
          className="text-decoration-none"
          style={{ cursor: 'pointer', color: isDaytime ? 'black' : 'white' }}
        >
          eunastudio
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              onClick={() => {
                navigate('/');
                setExpanded(false);
              }}
              className="text-decoration-none text-white"
            >
              Home
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                navigate('/about');
                setExpanded(false);
              }}
              className="text-decoration-none text-white"
            >
              Nosotros
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                navigate('/politics');
                setExpanded(false);
              }}
              className="text-decoration-none text-white"
            >
              Política de Privacidad
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                navigate('/faq');
                setExpanded(false);
              }}
              className="text-decoration-none text-white"
            >
              Preguntas frecuentes
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto align-items-center">
            {user?.photoURL ? (
              <Image
                src={user.photoURL}
                roundedCircle
                width={40}
                height={40}
                alt="Foto de perfil"
                className="me-2"
                onError={(e) => (e.target.src = 'https://via.placeholder.com/40')}
              />
            ) : (
              <Image
                src="https://via.placeholder.com/40"
                roundedCircle
                width={40}
                height={40}
                alt="Sin foto"
                className="me-2"
              />
            )}
            <NavDropdown
              title={user?.email || 'Usuario'}
              id="user-dropdown"
              align="end"
            >
              {user ? (
                <>
                  <NavDropdown.Item disabled>
                    <strong>Nombre:</strong> {user.nombre || 'Usuario sin nombre'}
                  </NavDropdown.Item>
                  <NavDropdown.Item disabled>
                    <strong>Correo:</strong> {user.email || 'Correo no disponible'}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>
                    Cerrar Sesión
                  </NavDropdown.Item>
                </>
              ) : (
                <>
                  <NavDropdown.Item disabled>
                    No hay usuario autenticado
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>
                    Cerrar Sesión
                  </NavDropdown.Item>
                </>
              )}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
