import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Card, Form, Modal, CardBody } from 'react-bootstrap';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from './firebase/firebaseConfig';

// Función para guardar resultados en Firestore y localStorage
const saveTriviaResult = async (correctas, incorrectas, totalPreguntas, tipoEvaluacion) => {
  const auth = getAuth();
  const user = auth.currentUser;

  const newEvaluation = {
    correctas,
    incorrectas,
    totalPreguntas,
    tipoEvaluacion,
    fecha: new Date(),
    userId: user ? user.uid : 'anónimo',
  };

  if (user) {
    try {
      await addDoc(collection(db, 'history'), newEvaluation);
      console.log('Evaluación guardada en Firestore');
    } catch (error) {
      console.error('Error al guardar en Firestore:', error);
    }
  }

  const storedEvaluations = JSON.parse(localStorage.getItem('historyEvaluations')) || [];
  storedEvaluations.push(newEvaluation);
  localStorage.setItem('historyEvaluations', JSON.stringify(storedEvaluations));
  console.log('Evaluación guardada en localStorage');
};

const Trivia = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [triviaStarted, setTriviaStarted] = useState(false);
  const [selectedTime, setSelectedTime] = useState(10 * 60);
  const [showModal, setShowModal] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'questions'));
        let loadedQuestions = querySnapshot.docs.map((doc) => doc.data());

        // Filtrar preguntas para evitar repeticiones basadas en el contenido de la pregunta
        const uniqueQuestions = Array.from(
          new Set(loadedQuestions.map((q) => q.question))
        ).map((uniqueQuestion) =>
          loadedQuestions.find((q) => q.question === uniqueQuestion)
        );

        const shuffledQuestions = uniqueQuestions.sort(() => Math.random() - 0.5);
        setQuestions(shuffledQuestions);
      } catch (error) {
        console.error('Error al cargar las preguntas de Firestore:', error);
      }
    };

    loadQuestions();
  }, []);

  useEffect(() => {
    let timer;
    if (triviaStarted && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0 && triviaStarted) {
      setFinished(true);
      setShowModal(true);
    }
    return () => clearInterval(timer);
  }, [triviaStarted, timeRemaining]);

  const handleAnswerClick = (option) => {
    const currentQuestion = questions[currentQuestionIndex];
    const isCorrect = option === currentQuestion.answer;

    setScore((prev) => prev + (isCorrect ? 1 : 0));
    setIncorrectAnswers((prev) => prev + (isCorrect ? 0 : 1));

    setUserAnswers((prevAnswers) => [
      ...prevAnswers,
      {
        question: currentQuestion.question,
        correctAnswer: currentQuestion.answer,
        userAnswer: option,
        isCorrect,
        feedback: currentQuestion.feedback,
      },
    ]);

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      setFinished(true);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowFeedback(true);
  };

  const handleStartTrivia = () => {
    setTimeRemaining(selectedTime);
    setTriviaStarted(true);
  };

  const calculatePercentage = () => {
    const totalRespondidas = score + incorrectAnswers;
    return totalRespondidas > 0 ? ((score / totalRespondidas) * 100).toFixed(2) : 0;
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Container className="mt-5" >
      {!triviaStarted ? (
        <div className="text-center">
          <Form style={{ marginTop: "5rem" }}>
            <h2>Bienvenido a Trivia</h2>
            <Form.Group controlId="formTimeSelect" centered>
              <Form.Label>Selecciona la duración:</Form.Label>
              <Form.Control
                as="select"
                value={selectedTime}
                onChange={(e) => setSelectedTime(Number(e.target.value))}
              >
                <option value={10 * 60}>10 minutos</option>
                <option value={50 * 60}>50 minutos</option>
                <option value={70 * 60}>70 minutos</option>
                <option value={90 * 60}>90 minutos</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" onClick={handleStartTrivia} className="mt-3">
              Comenzar Trivia
            </Button>
          </Form>
        </div>
      ) : showFeedback ? (
        <Container className="mt-5" style={{ marginTop: "5rem" }}>
          <h2>Feedback</h2>
          <Row >
            {userAnswers.map((answer, index) => (
              <Card key={index} style={{marginTop:"0.5rem"}}>
                <strong>Pregunta:</strong> {answer.question} <br />
                <strong>Tu respuesta:</strong> {answer.userAnswer} <br />
                <strong>Respuesta correcta:</strong> {answer.correctAnswer} <br />
                <strong>Feedback:</strong> {answer.feedback} <br />
              </Card>
            ))}
          </Row>
        </Container>
      ) : (
        <Row style={{ marginTop: "5rem" }}>
          <Col md={{ span: 8, offset: 2 }}>
            <Card>
              <Card.Body>
                <Card.Title>{questions[currentQuestionIndex]?.question}</Card.Title>
                <Row>
                  {questions[currentQuestionIndex]?.options.map((option, idx) => (
                    <Col xs={12} key={idx} className="mb-2">
                      <Button
                        variant="primary"
                        onClick={() => handleAnswerClick(option)}
                        className="w-100"
                      >
                        {option}
                      </Button>
                    </Col>
                  ))}
                </Row>
                <p>Tiempo restante: <strong style={{ fontSize: "2rem" }}>{formatTime(timeRemaining)}</strong></p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body className="text-center">
          <h2>Resultados de la Trivia</h2>
          <p>Total de preguntas respondidas: {score + incorrectAnswers}</p>
          <p>Correctas: {score}</p>
          <p>Incorrectas: {incorrectAnswers}</p>
          <p>Porcentaje de aprobación: {calculatePercentage()}%</p>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Trivia;
