import React, { useState, useEffect } from 'react';
import { Button, Carousel, Col, Row,Image, Container } from 'react-bootstrap';
import { FaGoogle } from 'react-icons/fa';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from '../firebase/firebaseConfig';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import logo from '../assets/logo.png';
import { BiBorderRadius } from 'react-icons/bi';

const WelcomeScreen = ({setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showIntro, setShowIntro] = useState(true);

  // Obtener comentarios de Firestore y configurar el temporizador para la introducción
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const q = query(collection(db, 'comments'), orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(q);
        const fetchedComments = querySnapshot.docs.map((doc) => doc.data());
        console.log('Comentarios obtenidos:', fetchedComments);
        setComments(fetchedComments);
      } catch (error) {
        console.error('Error al recuperar los comentarios: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchComments();

    // Temporizador para mostrar el componente Serch durante 20 segundos
    const timer = setTimeout(() => setShowIntro(false), 10000); // 10 segundos
    return () => clearTimeout(timer); // Limpiar el temporizador al desmontar el componente
  }, []);

  // Manejo de autenticación con Google
  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        setIsAuthenticated(true);
        navigate('/home');
      })
      .catch((error) => {
        console.error('Error durante la autenticación con Google:', error);
      });
  };

  return (
    
    
        <>
          <div
            className="welcome-screen d-flex flex-column justify-content-center align-items-center text-center"
            style={{ minHeight: '100vh', overflow: 'auto', padding: '10px' }}
          >
            {showIntro ? (
              // Mostrar el componente Search y el Spinner durante la introducción
              <div className="loader d-flex flex-column justify-content-center align-items-center">
                <img 
                   src={logo} 
                   className="img-fluid w-75" 
                   alt="Logo" 
                  />
              </div>
            ) : (
              // Contenido principal después de la introducción
              <div style={{ marginTop: '5%' }}>
               <div className="loader d-flex flex-column justify-content-center align-items-center">
                <h1 
                   className='text-blue'
                  style={{fontSize:"3rem"}}
                   >
                    BIENVENIDOS</h1>
               </div>
                
                <br />
    
                {/* Botón para autenticación con Google */}
              <Row className="justify-content-center">
                <Col xs={12} md={6} className="text-center">
                  <Image
                    src={logo || "/placeholder.svg"}
                    fluid
                    className="w-75"
                    alt="Logo"
                    style={{
                      borderRadius: "7rem",
                      maxWidth: "300px",
                      marginBottom:"4rem"
                    }}
                  />
                </Col>
              </Row>
                <Button
                  variant="danger"
                  size="lg"
                  className="welcome-button google-button mb-3"
                  onClick={handleGoogleSignIn}
                  style={{ width: '100%', maxWidth: '300px' }}
                >
                  <FaGoogle className="me-2" /> Continuar con Google
                </Button>
    
                <br />
                <hr />
              </div>
            )}
          </div>
    
          <Container style={{marginTop:"2rem", marginBottom:"2rem"}}> {/* Mostrar comentarios en un carrusel */}
            <br />
    
            {comments.length > 0 ? (
              <Carousel className="mb-4" interval={3000} indicators controls>
                {comments.map((comment, index) => (
                  <Carousel.Item key={index}>
                    <Row className="d-flex justify-content-center align-items-center">
                      <Col md={3} sm={12} className="text-center">
                        <img
                          src={comment.avatar}
                          alt="Avatar"
                          className="rounded-circle mb-3"
                          style={{ width: '100px', height: '100px' }}
                        />
                      </Col>
                      <Col md={9} sm={12} className='text-center'>
                        <p className="comment-user">
                          <strong>{comment.userName}</strong>
                        </p>
                        <b className="comment-text">{comment.text}</b>
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <p>No hay comentarios disponibles.</p>
            )}
          </Container><br/>
         
        </>
      );
    };
    

export default WelcomeScreen;
