import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../components/css/global.css'; // Asegúrate de incluir estilos globales si es necesario

const PageNotFound = () => {
  return (
    <Container className="text-center" style={{ marginTop: '10%', padding: '20px' }}>
      <Row>
        <Col>
          <h1 style={{ fontSize: '4rem', color: '#ff6f61' }}>404</h1>
          <p style={{ fontSize: '1.5rem' }}>¡Oops! La página que buscas no existe.</p>
          <p>Es posible que la URL esté incorrecta o que la página haya sido movida.</p>
          <Button as={Link} to="/" variant="primary" className="mt-3">
            Volver al Inicio
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
