import React, { useState, useEffect } from 'react';
import { db } from './firebase/firebaseConfig';
import { collection, query, getDocs, addDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Container, Row, Col, Card, Modal, Button, Form } from 'react-bootstrap';
import './style.css';

const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

const ExamenPorEspecialidad = () => {
  const [speciality, setSpecialty] = useState('');
  const [time, setTime] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [examFinished, setExamFinished] = useState(false);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [feedback, setFeedback] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [passStatus, setPassStatus] = useState(null);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false); // Nuevo estado para controlar el feedback

  // Fetch questions from Firebase
  useEffect(() => {
    const fetchQuestions = async () => {
      if (speciality) {
        try {
          const specialityPath = `xespecialidad/${speciality}/questions`;
          const q = query(collection(db, specialityPath));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const fetchedQuestions = querySnapshot.docs.map((doc) => doc.data());
            const shuffledQuestions = shuffleArray(fetchedQuestions).map((question) => ({
              ...question,
              options: shuffleArray([...question.options]),
            }));
            setQuestions(shuffledQuestions);
          } else {
            console.error('No hay preguntas disponibles para esta especialidad.');
          }
        } catch (error) {
          console.error('Error al cargar las preguntas desde Firestore:', error);
        }
      }
    };
    fetchQuestions();
  }, [speciality]);

  // User authentication
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser || null);
    });
    return () => unsubscribe();
  }, []);

  // Start the exam
  const handleStartExam = () => {
    if (speciality && time && phoneNumber) {
      setIsReady(true);
      setTimeRemaining(parseInt(time) * 60); // Convert minutes to seconds
    } else {
      alert('Por favor, completa todos los campos antes de iniciar el examen.');
    }
  };

  // Timer
  useEffect(() => {
    let timer;
    if (timeRemaining > 0 && isReady) {
      timer = setInterval(() => setTimeRemaining((prev) => prev - 1), 1000);
    } else if (timeRemaining === 0 && isReady) {
      calculateResult();
      setShowModal(true);
    }
    return () => clearInterval(timer);
  }, [timeRemaining, isReady]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // Handle answer selection
  const handleAnswerSelection = (questionIndex, option) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[questionIndex] = option;
    setSelectedAnswers(updatedAnswers);
  };

  // Calculate results
  const calculateResult = async () => {
    let correctCount = 0;
    let answeredCount = 0;

    const feedbackData = questions.map((question, index) => {
      const userAnswer = selectedAnswers[index];
      const isCorrect = userAnswer === question.answer;
      if (userAnswer !== undefined) {
        answeredCount++;
        if (isCorrect) correctCount++;
      }
      return {
        question: question.question,
        selectedAnswer: userAnswer,
        correctAnswer: question.answer,
        isCorrect,
        feedback: question.feedback,
      };
    });

    const incorrectCount = answeredCount - correctCount;

    setCorrectAnswersCount(correctCount);
    setFeedback(feedbackData);

    const correctPercentage = answeredCount
      ? ((correctCount / answeredCount) * 100).toFixed(2)
      : 0;

    setPassStatus(correctPercentage >= 51 ? 'Aprobado' : 'Reprobado');
    setExamFinished(true);

    if (user) {
      try {
        await addDoc(collection(db, 'userEvaluations'), {
          correctas: correctCount,
          incorrectas: incorrectCount,
          preguntasRespondidas: answeredCount,
          tipoEvaluacion: speciality,
          fecha: new Date(),
          userId: user.uid,
          correctPercentage,
          passStatus: correctPercentage >= 51 ? 'Aprobado' : 'Reprobado',
        });
      } catch (error) {
        console.error('Error al guardar los resultados:', error);
      }
    }
  };
  return (
    <div className="exam-container">
      {!isReady || showFeedback ? (
        <>
          {showFeedback ? (
            <Container className="mt-4">
              <h2>Feedback</h2>
              {feedback
      .filter((item) => item.selectedAnswer !== undefined) // Filtrar solo preguntas respondidas
      .map((item, index) => (
                <Card
                  key={index}
                  className={`mb-3 ${item.isCorrect ? 'border-success' : 'border-danger'}`}
                >
                  <Card.Body>
                    <Card.Title>{`Pregunta ${index + 1}`}</Card.Title>
                    <Card.Text>
                      <strong>Tu respuesta:</strong> {item.selectedAnswer || 'No respondida'}
                    </Card.Text>
                    {!item.isCorrect && (
                      <Card.Text>
                        <strong>Respuesta correcta:</strong> {item.correctAnswer}
                      </Card.Text>
                    )}
                    <Card.Text>
                      <strong>Feedback:</strong> {item.feedback || 'Sin detalles.'}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </Container>
          ) : (
            <div className="exam-box">
              <h1 className="text-center mb-4">Examen por Especialidad</h1>
              <Form>
                <Form.Group controlId="speciality" className="mb-3">
                  <Form.Label>Seleccionar Especialidad</Form.Label>
                  <Form.Select
                    value={speciality}
                    onChange={(e) => setSpecialty(e.target.value)}
                  >
                    <option value="">Seleccionar Especialidad</option>
                    <option value="Salud Publica">Salud Pública</option>
                    <option value="Pediatría">Pediatría</option>
                    <option value="Psiquiatria">Psiquiatría</option>
                    <option value="Otorrinolaringologia">Otorrinolaringología</option>
                    <option value="Oftalmologia">Oftalmología</option>
                    <option value="Cirugía">Cirugía</option>
                    <option value="Ginecología y Obstetricia">Ginecología y Obstetricia</option>
                    <option value="Medicina Interna">Medicina Interna</option>
                    <option value="Especialidades">Mix - Especialidades</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="time" className="mb-3">
                  <Form.Label>Seleccionar Tiempo</Form.Label>
                  <Form.Select value={time} onChange={(e) => setTime(e.target.value)}>
                    <option value="">Seleccionar tiempo</option>
                    <option value="10">10 minutos</option>
                    <option value="20">20 minutos</option>
                    <option value="30">30 minutos</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="phoneNumber" className="mb-3">
                  <Form.Label>Número de WhatsApp</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Número de WhatsApp"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Form.Group>

                <Button className="w-100" variant="primary" onClick={handleStartExam}>
                  Iniciar Examen
                </Button>
              </Form>
            </div>
          )}
        </>
      ) : (
        <Container>
          <div
            className="timer text-end mb-4"
            style={{
              position: 'fixed',
              top: '10px',
              right: '10px',
              zIndex: 1000,
              backgroundColor: 'white',
              padding: '10px 15px',
              borderRadius: '8px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              marginTop:"10rem"
            }}
          >
            <h4>
              Tiempo: <strong>{formatTime(timeRemaining)}</strong>
            </h4>
          </div>
          {questions.slice(currentBlockIndex, currentBlockIndex + 10).map((question, index) => (
            <Row key={index} className="mb-4">
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <Card.Title>{`Pregunta ${currentBlockIndex + index + 1}`}</Card.Title>
                    <Card.Text>{question.question}</Card.Text>
                    <ul className="list-unstyled">
                      {question.options.map((option, optIndex) => (
                        <li
                          key={optIndex}
                          className={`p-2 mb-2 rounded ${
                            selectedAnswers[currentBlockIndex + index] === option
                              ? 'bg-primary text-white'
                              : 'bg-light'
                          }`}
                          onClick={() => handleAnswerSelection(currentBlockIndex + index, option)}
                          style={{ cursor: 'pointer' }}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
          <Row>
            <Col className="text-center">
              <Button
                variant="success"
                onClick={() => {
                  if (currentBlockIndex + 10 < questions.length) {
                    setCurrentBlockIndex(currentBlockIndex + 10);
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth', // Desplazamiento suave
                    });
                  } else {
                    calculateResult();
                    setShowModal(true);
                  }
                }}
              >
                {currentBlockIndex + 10 < questions.length
                  ? 'Siguiente Bloque'
                  : 'Finalizar Examen'}
              </Button>
            </Col>
          </Row>
        </Container>
      )}

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setShowFeedback(true);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Resultados del Examen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Preguntas respondidas: {feedback.filter((item) => item.selectedAnswer !== undefined).length}</p>
          <p>Correctas: {correctAnswersCount}</p>
          <p>Incorrectas: {feedback.filter((item) => item.selectedAnswer !== undefined).length - correctAnswersCount}</p>
          <p>
            Porcentaje de aprobación:{' '}
            {(
              (correctAnswersCount /
                feedback.filter((item) => item.selectedAnswer !== undefined).length) *
              100
            ).toFixed(2)}
            %
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowModal(false);
              setShowFeedback(true);
              window.scrollTo({
                top: 0,
                behavior: 'smooth', // Desplazamiento suave
              });
            }}
          >
            Ver Feedback
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExamenPorEspecialidad;
