import React, { useState, useEffect } from 'react';
import { Button, Container, Card, Form, Modal } from 'react-bootstrap';
import { collection, getDocs,addDoc } from 'firebase/firestore';
import { db } from './firebase/firebaseConfig'; // Configuración de Firebase

const Exam = () => {
  const [questions, setQuestions] = useState([]);
  const [currentBlock, setCurrentBlock] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [finished, setFinished] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0); // Tiempo en segundos
  const [examStarted, setExamStarted] = useState(false);
  const [selectedTime, setSelectedTime] = useState(600); // Tiempo seleccionado por defecto (10 minutos)
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  // Función para cargar preguntas desde Firestore
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'questions'));
        const fetchedQuestions = querySnapshot.docs.map((doc) => doc.data());
        setQuestions(fetchedQuestions);
      } catch (error) {
        console.error('Error al cargar las preguntas desde Firestore:', error);
      }
    };
    fetchQuestions();
  }, []);

  const saveResultsToFirebase = async (results) => {
    try {
      const docRef = await addDoc(collection(db, 'examResults'), {
        totalCorrect: results.totalCorrect,
        totalIncorrect: results.totalIncorrect,
        totalAnswered: results.totalAnswered,
        timestamp: new Date(), // Fecha y hora de la evaluación
      });
      console.log('Resultados guardados con ID:', docRef.id);
    } catch (error) {
      console.error('Error al guardar los resultados en Firebase:', error);
    }
  };
  
 

  // Manejar el cronómetro
  useEffect(() => {
    if (examStarted && timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeRemaining === 0 && examStarted) {
      setShowResultsModal(true);
      setFinished(true);
    }
  }, [examStarted, timeRemaining]);

  const handleAnswerChange = (questionIndex, option) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: option,
    }));
  };

  const handleNextBlock = () => {
    if ((currentBlock + 1) * 10 < questions.length) {
      setCurrentBlock(currentBlock + 1);
    } else {
      setShowResultsModal(true);
      setFinished(true);
    }
  };

  const calculateResult = () => {
    const answeredQuestions = Object.keys(userAnswers);
    const correctAnswers = answeredQuestions.filter(
      (index) => userAnswers[index] === questions[index]?.answer
    );

    return {
      totalCorrect: correctAnswers.length,
      totalIncorrect: answeredQuestions.length - correctAnswers.length,
      totalAnswered: answeredQuestions.length,
    };
  };

  const calculateFeedback = () => {
    return Object.keys(userAnswers).map((key) => {
      const index = parseInt(key);
      return {
        question: questions[index]?.question || `Pregunta ${index + 1}`,
        userAnswer: userAnswers[index],
        correctAnswer: questions[index]?.answer || 'No especificada',
        feedback: questions[index]?.feedback || 'Explicación no disponible',
      };
    });
  };

  const startExam = () => {
    setTimeRemaining(selectedTime);
    setExamStarted(true);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

// Llama a esta función después de calcular los resultados
  const results = calculateResult();
  saveResultsToFirebase(results);
  const feedbackData = calculateFeedback();
  const currentQuestions = questions.slice(currentBlock * 10, (currentBlock + 1) * 10);

  return (
    <Container style={{ marginTop: '5rem' }}>
      {!examStarted ? (
        <div>
          <h1>Bienvenido al examen</h1>
          <Form>
            <Form.Group>
              <Form.Label>Selecciona la duración del examen</Form.Label>
              <Form.Select
                value={selectedTime}
                onChange={(e) => setSelectedTime(Number(e.target.value))}
              >
                <option value={600}>10 minutos</option>
                <option value={1800}>30 minutos</option>
                <option value={2700}>45 minutos</option>
                <option value={3600}>60 minutos</option>
              </Form.Select>
            </Form.Group>
          </Form>
          <Button onClick={startExam}>Iniciar Examen</Button>
        </div>
      ) : showFeedback ? (
        <div>
          <h2>Feedback</h2>
          {feedbackData.map((item, idx) => (
            <Card key={idx} className="mb-3">
              <Card.Body>
                <Card.Title>{item.question}</Card.Title>
                <p><strong>Tu respuesta:</strong> {item.userAnswer}</p>
                <p><strong>Respuesta correcta:</strong> {item.correctAnswer}</p>
                <p><strong>Feedback:</strong> {item.feedback}</p>
              </Card.Body>
            </Card>
          ))}
        </div>
      ) : (
        <div>
          <h3>Tiempo restante: {formatTime(timeRemaining)}</h3>
          {currentQuestions.map((question, idx) => (
            <Card key={idx} className="mb-3">
              <Card.Body>
                <Card.Title>{question.question}</Card.Title>
                {question.options.map((option, optionIdx) => (
                  <Form.Check
                    key={optionIdx}
                    type="radio"
                    label={option}
                    name={`question-${currentBlock * 10 + idx}`}
                    checked={userAnswers[currentBlock * 10 + idx] === option}
                    onChange={() => handleAnswerChange(currentBlock * 10 + idx, option)}
                  />
                ))}
              </Card.Body>
            </Card>
          ))}
          <Button
            onClick={() => {
              handleNextBlock();
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
            disabled={(currentBlock + 1) * 10 >= questions.length}
          >
            Siguiente Bloque
          </Button>
        </div>
      )}

      <Modal show={showResultsModal} onHide={() => setShowFeedback(true)}>
        <Modal.Header closeButton>
          <Modal.Title>Resultados del Examen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Total preguntas respondidas: {results.totalAnswered}</p>
          <p>Correctas: {results.totalCorrect}</p>
          <p>Incorrectas: {results.totalIncorrect}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {
            setShowFeedback(true);
            setShowResultsModal(false);
          }}>
            Ver Feedback
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Exam;
