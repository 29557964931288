import React from 'react';
import './TelegramButton.css'; // Importa los estilos CSS para el botón
import telegram from "./telegram.png";

const TelegramButton = () => {
  const telegramLink = "https://t.me/+9pWfjFjN6t83Njhh"; // Reemplaza con tu enlace de Telegram

  return (
    <a
      href={telegramLink}
      target="_blank"
      rel="noopener noreferrer"
      className="telegram-button"
    >
      <img
        src={telegram}
        alt="Telegram"
        className="telegram-icon"
      />
    </a>
  );
};

export default TelegramButton;
