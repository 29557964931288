import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db, auth } from './firebase/firebaseConfig';
import TiraInformativa from './components/TiraInformativa';
import CardGrid from './components/cards';
import TelegramButton from './service/TelegramButton';


import './Home.css'; // Importa el CSS para el fondo interactivo

const Home = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);

  const handleStartSimulation = () => navigate('/simulacion180');
  const handleStartTrivia = () => navigate('/trivia');
  const handleStartExamen = () => navigate('/examen');
  const handleViewHistorial = () => navigate('/history');
  const handleStartExamenEspecialidad = () => navigate('/examen-especialidad');
  const handleStartPractico = () => navigate('/practico');


  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (newComment.trim() !== '') {
      try {
        const user = auth.currentUser;
        const avatar = user.photoURL || 'https://via.placeholder.com/150';
        const userName = user.displayName || 'Usuario Anónimo';

        await addDoc(collection(db, 'comments'), {
          text: newComment,
          avatar: avatar,
          userName: userName,
          timestamp: new Date(),
        });
        setNewComment('');
      } catch (error) {
        console.error('Error al guardar el comentario: ', error);
      }
    }
  };

  useEffect(() => {
    const q = query(collection(db, 'comments'), orderBy('timestamp', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedComments = snapshot.docs.map((doc) => doc.data());
      setComments(fetchedComments);
    });

    return () => unsubscribe();
  }, []);

  return (

    <div className="interactive-background text-center">

      <Container fluid className="p-5 text-center">
        {/* Banner de Cabecera */}
        <div
          style={{
            backgroundImage: `url(${require('./assets/banner.png')})`, // Cambia la ruta a tu imagen de banner
            height: '300px', // Ajusta la altura según sea necesario
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            marginBottom: '20px'
          }}
        >
        </div>
        <p className="lead bg-secondary text-black">Prepárate para el EUNACOM con nuestras simulaciones y exámenes</p>


        <TiraInformativa />

        {/* Tarjetas */}

       <CardGrid />

        {/* Sección de Comentarios */}
        {isAuthenticated && (
          <div className="my-5 text-black">
            <h3>Deja tu comentario</h3>
            <Form onSubmit={handleCommentSubmit}>
              <Form.Group controlId="formComment" className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Escribe tu comentario..."
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">Enviar comentario</Button>
            </Form>

            <div className="mt-5 text-black text-center">
              <h3>Comentarios</h3>
              {comments.length > 0 ? (
                <div className="container">
                  {comments.map((comment, index) => (
                    <div
                      key={index}
                      className="row align-items-center mb-3 p-2 border-bottom"
                    >
                      <div className="col-auto">
                        <img
                          src={comment.avatar}
                          alt="Avatar"
                          className="rounded-circle"
                          style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                        />
                      </div>
                      <div className="col text-start">
                        <p className="mb-0 fw-bold">{comment.userName}</p>
                        <p className="mb-0">{comment.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-muted">No hay comentarios disponibles.</p>
              )}
            </div>
          </div>
        )}
      </Container>
      <TelegramButton />
    </div>
  );
};

export default Home;